import React from "react"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"

import LayoutCourse from "../../../../../layouts/course"

import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"

import LetterNote from "../../../../../content/kurse/reiseerinnerung/01-suedfrankreich/letter-note"
import LetterSidebar from "../../../../../content/kurse/reiseerinnerung/01-suedfrankreich/letter-sidebar"
import MoodBarometer from "../../../../../content/kurse/reiseerinnerung/01-suedfrankreich/mood-barometer"
import AudioPlayer from "../../../../../components/audio-player"
import Paragraph from "../../../../../components/paragraph"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/01-suedfrankreich/stimmungsbarometer/zusammenfassung" />
      }
    >
      <Seo title="Hölderlin im Stimmungsbarometer" />
      <Stack>
        <Heading as="h2" level={2}>
          Hölderlin im Stimmungsbarometer
        </Heading>
        <Paragraph>
          Und wie sieht es mit Hölderlins Stimmung kurz nach seiner Ankunft in
          Bordeaux aus?
        </Paragraph>
        <LetterNote />
        <LetterSidebar
          title="Brief an die Mutter. Bordeaux, den 28. Januar 1802"
          before={<AudioPlayer src={`kurse/reiseerinnerung/brief-3`} />}
          main={
            <>
              <p>
                Endlich, meine Mutter, bin ich hier, bin wohl aufgenommen, bin
                gesund und will den Dank ja nicht vergessen, den ich dem Herrn
                des Lebens und des Todes schuldig bin. – Ich kann für jezt nur
                wenig schreiben; diesen Morgen bin ich angekommen, und meine
                Aufmerksamkeit ist noch zu sehr auf meine neue Lage gerichtet,
                um mit Ruhe Ihnen einiges Interessante von der überstandenen
                Reise zu sagen. Überdiß hab‘ ich so viel erfahren, daß ich kaum
                noch reden kann davon.
              </p>
              <br />
              <p>
                Diese letzten Tage bin ich schon in Einem schönen Frühlinge
                gewandert, aber kurz zuvor, auf den gefürchteten überschneiten
                Höhen der Auvergne, in Sturm und Wildniß, in eiskalter Nacht und
                die geladene Pistole neben mir im rauhen Bette – da hab‘ ich
                auch ein Gebet gebetet, das bis jezt das beste war in meinem
                Leben und das ich nie vergessen werde.
              </p>
              <br />
              <p>Ich bin erhalten – danken Sie mit mir!</p>
              <br />
              <p>
                Ihr Lieben! ich grüßt‘ Euch wie ein Neugeborner, da ich aus den
                Lebensgefahren heraus war [...] Ich bin nun durch und durch
                gehärtet und geweiht, wie Ihr es wollt. Ich denke, ich will so
                bleiben, in der Hauptsache. Nichts fürchten und sich viel
                gefallen lassen. Wie wird mir der sichere erquikende Schlaf wohl
                thun! Fast wohn‘ ich zu herrlich. Ich wäre froh an sicherer
                Einfalt. Mein Geschäfft soll, wie ich hoffe, gut gehn. Ich will
                mich ganz dem wiedmen, besonders von Anfang. Lebet wohl! Von
                Herzen und mit Treue{" "}
              </p>
            </>
          }
          sidebar={
            <MoodBarometer
              onChange={({ state }) => {
                setAnswer({
                  courseId: "reiseerinnerung",
                  chapterId: "01-suedfrankreich",
                  taskId: "stimmungsbarometer-3",
                  answer: state,
                })
              }}
            />
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
